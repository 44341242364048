import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import loading_img from "../../../images/profile/loading.gif";
import { Router } from "react-router-dom";
import { GlobalSet } from '../global/GlobalProvider';
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import Slider from "react-slick";
import swal from "sweetalert";


const Home = () => {
   const { BaseUrl } = GlobalSet();
   const [homeitem, setHomeItem] = useState([]);  
    useEffect(()=>{
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
         axios.post('/casino-providers',data)
         .then(response=>{
           setHomeItem(response.data.casino_providers)
           
           })
           .catch(error=>{
             console.log(error)
         })


    },[])


  const [isCollapsed, setCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-1" >
      






              <Card.Body className="p-2" >
              <div className="form-inline">
                <div className="row">
{/*        <div>
          <p>
            <button
              className="btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#collapseWidthExample"
              aria-expanded={isCollapsed ? 'true' : 'false'}
              aria-controls="collapseWidthExample"
              onClick={handleToggleCollapse}
            >
              Toggle width collapse
            </button>
          </p>
          <div>
            <div className={`collapse ${isCollapsed ? 'show' : ''}`} id="collapseWidthExample">
              <div className="card card-body" style={{ width: '320px' }}>
                This is some placeholder content for a horizontal collapse. It's hidden by default and shown when triggered.
              </div>
            </div>
          </div>
        </div>*/}

                   {homeitem.map((ele) => (
                      <div className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1">   
                            <div key={ele.id}>
                              <Link to={"/home-game/all-game?provider="+ele.slug} className="text-black">

                                  <div className="card mb-0">
                                  <div className="img-bx text-center ">
                                      <img src={(BaseUrl+ele.icon)} alt="" className="me-3 m-3 card-list-img" width="35%" />
                                      <h5 className="nav-text text-center" style={{display: "block"}}> {ele.providers_name}</h5>

                                  </div>  
                                  </div>  
                               </Link>
                            </div>  
                        </div>  
                        ))}

                </div>
              </div>



              </Card.Body>


          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;